<template>

<!-- <a
      id="muala-logo"
      class="cursor-pointer z-20 absolute sm:fixed inset-x-0 top-4 ml-5 flex justify-center pb-4 transition-transform duration-300 hover:-translate-y-full"
      style="width: 44px; height: 120px"
    >
      <img
        src="@/assets/MUALA-logo.svg"
        style="width: 100%; height: 100%"
      />
    </a> -->

    <a
      id="muala-logo"
      class="cursor-pointer z-20 absolute sm:fixed inset-x-0 top-3 ml-2 flex justify-center w-48"
    >
    <img
        src="@/assets/muala-unofficial.svg" alt="logo Muala unofficial" style="width: 100%; height: 100%"
      />
    </a>

    
</template>