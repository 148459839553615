
export function applyFilters(filtersData, allMarkers) {
   
  

    if (!filtersData || filtersData.length === 0) {
      console.error("filtersData jest pusty lub niezdefiniowany");
      return; 
    }
  
    let updatedMarkers = [];
  
    // Jeśli 'Wszystkie' jest włączone, po prostu przypisz wszystkie markery
    if (filtersData[0].enabled) {
      updatedMarkers = [...allMarkers];
    } else {
      // Zbierz aktywne filtry (oprócz 'Wszystkie')
      const activeFilters = filtersData.filter(
        (filter) => filter.id !== 0 && filter.enabled
      );
      const specialFilters = activeFilters
        .filter((filter) => filter.isSpecial)
        .map((filter) => filter.name);
      const regularFilters = activeFilters
        .filter((filter) => !filter.isSpecial)
        .map((filter) => filter.name);
  
      // Filtruj markery na podstawie aktywnych filtrów
      updatedMarkers = allMarkers.filter((marker) => {
        const markerTypes = Array.isArray(marker.type)
          ? marker.type
          : [marker.type];
  
        // Dla każdego markera, sprawdź czy pasuje do aktywnych filtrów regularnych
        const matchesRegularFilters =
          regularFilters.length > 0
            ? markerTypes.some((type) => regularFilters.includes(type))
            : true;
  
        // Sprawdź, czy marker pasuje do aktywnych filtrów specjalnych
        const matchesSpecialFilters =
          specialFilters.length > 0
            ? specialFilters.some((special) => markerTypes.includes(special))
            : true;
  
        return matchesRegularFilters && matchesSpecialFilters;
      });
    }
  
    return updatedMarkers
    emits("update:markers", updatedMarkers);
  }