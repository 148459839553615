
const diacriticsMap = {
    'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n',
    'ó': 'o', 'ś': 's', 'ż': 'z', 'ź': 'z', 'i̇': 'i',
    'è': 'e', 'i̇': 'i', 'à': 'a', 'é': 'e', 'ô': 'o',
    'ü': 'u', 'ä': 'a', 'ö': 'o', 'ß': 'ss',
    'ç': 'c', 'ì': 'i', 'ò': 'o', 'ù': 'u',
    'á': 'a', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ñ': 'n',
    'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
    'ë': 'e', 'ï': 'i', 'ü': 'u', 'ÿ': 'y',
    'ã': 'a', 'õ': 'o', 'å': 'a', 'ø': 'o',
    'æ': 'ae', 'œ': 'oe', // Ligatury
    'ł': 'l', 'đ': 'd', 'þ': 'th', 'ħ': 'h',
    'ġ': 'g', 'ǰ': 'j', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
    'ŕ': 'r', 'š': 's', 'ţ': 't', 'ŧ': 'th',
    'ŵ': 'w', 'ŷ': 'y', 'ż': 'z', 'Ż': 'Z', 'Ž': 'z',
    'ź': 'z', 'ƒ': 'f', 'ơ': 'o', 'ư': 'u',
    'ǎ': 'a', 'ǐ': 'i', 'ǒ': 'o', 'ǔ': 'u', 'ǖ': 'u',
    'ǘ': 'u', 'ǚ': 'u', 'ǜ': 'u', 'ě': 'e', 'ǧ': 'g',
    'ǿ': 'o', // Dodatkowe znaki specjalne
    // Można dodać jeszcze więcej znaków według potrzeb
};

export const prepareUrlName = (nameRes) => {

    const removeDiacritics = (str) => str.split('').map(c => diacriticsMap[c] || c).join('');
    
    const ready = nameRes.toLowerCase()
        .replace(/'/g, '')
        .replace(/ - /g, ' ')
        .replace(/ /g, '-')
        .replace(/"/g, '')
        .replace(/\(/g, '') 
        .replace(/\)/g, '') 
        .replace(/\[/g, '') 
        .replace(/\]/g, '') 
        .replace(/\//g, '') 
        .replace(/\\/g, '')
        .replace(/\|/g, '') 
        .replace(/\./g, '')
        .replace(/--/g, '-')
        .split('') // Przekształć string na tablicę znaków
        .map(char => removeDiacritics(char))
        .join(''); // Połącz tablicę znaków z powrotem w string
 return ready
}
