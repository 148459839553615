<template>
  <div
    class="z-50 fixed top-5 right-5 sm:right-2 sm:top-2 flex items-center justify-end"
  >
    <button
      @click="showMenu()"
      class="rounded-full text-4xl sm:text-3xl p-2 hover:animate-spin flex justify-center items-center ml-auto cursor-pointer"
      aria-label="Toggle menu"
    >
      <span v-if="isMenu">🧅</span>
      <span v-else>🍔</span>
</button>
  </div>


<div v-if="isMenu">

    <div id="menu" class="fixed top-0 left-0 z-40 overflow-auto flex flex-col justify-center items-center pt-0 sm:pt-20 pb-20 bg-white text-black font-bold text-lg w-full h-screen">
      <!-- <muala-logo /> -->
      <div class="flex flex-col gap-y-[35px] justify-center text-3xl">
        <router-link :to="{ name: 'opis' }" class="hover:text-orange-500 transition duration-300" @click="isMenu = false">
          o pomyśle
        </router-link>

        <a href="https://buycoffee.to/mr.tomato" target="blank" class="hover:text-orange-500 transition duration-300">
          wsparcie projektu
        </a>

        <a href="mailto:kebab@muala.app" class="hover:text-orange-500 transition duration-300">kontakt</a>
<!--       
        <a href="https://forms.gle/gpdVCJoYZ2oyYqV68" target="blank" class="hover:text-orange-500 transition duration-300">
          🚨 zgłoś błąd
        </a> -->


      <div class="w-full z-20 text-xs text-orange-500 pt-2  text-center x-auto bg-white">
            <div>made with ❤️ ×  🌯 a flavor blast in every byte!</div>
      </div>

    </div>
    </div>


    <div class="fixed z-50 inset-x-0 bottom-0 flex justify-center pb-4">
        <div
          @click="showMenu()"
          class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
        >
          <span class="material-icons-round">close</span>
        </div>
  </div>
</div>



  <router-view />
</template>



<script setup>
import { ref } from "vue";
import Menu from "./components/Menu.vue";
import MualaLogo from "./components/MualaLogo.vue";
import { setupAnalytics } from '@/composables/analytics.js'

setupAnalytics();

const isMenu = ref(false);

const showMenu = () => {
  isMenu.value = !isMenu.value;
};
</script>




<style>
body {
  margin: 0px;
  padding: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
