<template>

<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full text-5xl">
                  🥖
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Aktualizacja Dostępna!</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Świeżutka jak bułeczka z pieca! Wprowadziłem smaczne zmiany. Kliknij "odświeżam stronę", aby skosztować najnowszych funkcji.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex w-full justify-center rounded-full border border-transparent bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm" @click="open = false, refreshPage()">odświeżam stronę</button>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
</TransitionRoot>



<MainPage v-model:firstVisit="firstVisit" v-if="firstVisit"/>


<div style="overflow: hidden" v-if="!firstVisit && isDataLoaded">

  <!-- <div style="overflow: hidden" v-if="isDataLoaded"> -->
    <MualaLogo class="hidden sm:fixed sm:flex cursor-pointer absolute inset-x-0 top-4 ml-5 justify-center pb-4 z-50" />


    <FiltersPopup
      v-if="isFilterVisible"
      v-model:isFilterVisible="isFilterVisible"
      v-model:isListVisible="isListVisible"
      v-model:allMarkers="allMarkers"
      v-model:filtersData="filtersData"
      v-model:markers="markers"
      v-model:selectedMarker="selectedMarker"
      v-model:center="center"
      v-model:search="search"
      v-model:zoom="zoom"
      v-model:isFiltered="isFilteredCompound"
    ></FiltersPopup>



    <ListPopup
      v-if="isListVisible"
      v-model:isListVisible="isListVisible"
      v-model:allMarkers="allMarkers"
      v-model:markers="markers"
      v-model:selectedMarker="selectedMarker"
      v-model:center="center"
      v-model:search="search"
      v-model:mistakesDict="mistakesDict"
      v-model:isFilterVisible="isFilterVisible"
      v-model:isFiltered="isFilteredCompound"
      v-model:zoom="zoom"
    ></ListPopup>



    <Map
      v-model:selectedMarkerId="selectedMarkerId"
      v-model:selectedMarker="selectedMarker"
      v-model:markers="markers"
      v-model:center="center"
      :zoom="zoom"
      v-model:isMapReady="isMapReady"
    ></Map>

    <Card 
    v-if="selectedMarker"
      v-model:selectedMarker="selectedMarker"
      v-model:allMarkers="allMarkersRaw"
      v-model:markers="markers"
      v-model:center="center"
      v-model:filtersData="filtersData"
      :zoom="zoom"
      :mistakesDict="mistakesDict"
      :search="search"
    ></Card>

    <MenuBottomBar
      v-model:selectedMarker="selectedMarker"
      v-model:allMarkers="allMarkers"
      v-model:markers="markers"
      v-model:isListVisible="isListVisible"
      v-model:isFilterVisible="isFilterVisible"
      v-model:filtersData="filtersData"
      :isFiltered="isFilteredCompound"
      :search="search"
      :mistakesDict="mistakesDict"
      
    ></MenuBottomBar>
   

  </div>

</template>

<script setup>
// import getCollection from "@/composables/getCollection";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'


import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { ref, onMounted, onUnmounted, onBeforeMount, computed, watchEffect, watch, } from "vue";

// import data from "../data/data.js";
const route = useRoute();
const router = useRouter();

import FiltersPopup from "@/components/FiltersPopup.vue";
import MualaLogo from "@/components/MualaLogo.vue";
import Map from "@/components/Map.vue";
import Card from "@/components/Card.vue";
import MenuBottomBar from "@/components/MenuBottomBar.vue";
import ListPopup from "@/components/ListPopup.vue";
import MainPage from "@/components/MainPage.vue";

import { prepareUrlName } from "@/composables/prepareUrlName.js"
// import getDescriptionsById from '@/composables/getDescriptionsById';
import  {msg}  from "@/composables/msg.js"
// import { applyFilters } from "@/composables/applyFilters.js"
import { locationRoutes } from "../data/locationRoutes";
import getDocById from '../composables/getDocById.js';

console.log(`%c${msg}`, 'color: orange;');


const props = defineProps({
  centerNew: Object,
  zoomNew: Number,
  nameNew: String,
});


const getAllSavedToFirebase = async () => {
  const doc = await getDocById('data', '1');
  return Object.values(doc.all);
}
const firstVisit = ref(true);
const selectedMarkerId = ref(null);
const selectedMarker = ref(null);

const isDataLoaded = ref(false);
const allMarkersRaw = ref([]);
const allMarkers = ref(null);
const markers = ref(null);

const search = ref("");
const center = ref();
const mistakesDict = ref({});
const isMapReady = ref(false)

const zoom = ref(11);
const isListVisible = ref(false);
const isFilterVisible = ref(false);

const filtersData = ref([
  { id: 0, name: "", text: "Wszystkie", enabled: true },
  { id: 1, name: "MUALA", text: "MUALA", enabled: true, isSpecial: true },
  { id: 10, name: "ostrooo", text: "ostrooo", enabled: true, isSpecial: true },
  { id: 2, name: "chińczyk", text: "Chińczyk", enabled: true },
  { id: 3, name: "burger", text: "Burger", enabled: true },
  { id: 4, name: "kebab", text: "Kebab", enabled: true },
  { id: 5, name: "zapiekanki", text: "Zapiekanka", enabled: true },
  { id: 6, name: "polska kuchnia", text: "Kuchnia polska", enabled: true },
  { id: 7, name: "pizza", text: "Pizza", enabled: true },
  // { id: 8, name: "mexican", text: "Kuchnia Mexican", enabled: true },
  { id: 9, name: "deserowo", text: "Deserowo", enabled: true },
]);

const open = ref(false)



onBeforeMount(async() => {
  const fetchedData = await getAllSavedToFirebase();

  let modifiedArray = fetchedData.map(item => ({
    ...item, 
    id: parseInt(item.id, 10), 

    position: {
      lat: item.position.latitude,
      lng: item.position.longitude
    },
    adres: {
      street: item.street,
      postcode: item.postcode,
      city: item.city
    },
  })).map(item => {
    delete item.street;
    delete item.postcode;
    delete item.city;
    return item;
  });

  allMarkersRaw.value = modifiedArray;


  const dataFlatReviews = modifiedArray.flatMap(marker => {
    if (marker.reviews && marker.reviews.length) {
      return marker.reviews.map(review => {
        const { reviews, ...restOfMarker } = marker; 
        return { ...restOfMarker, ...review }; 
      });
    }
    const { reviews, ...restOfMarker } = marker;
    return [restOfMarker];
    });

    allMarkers.value = dataFlatReviews;
    markers.value = dataFlatReviews;
    isDataLoaded.value = true;
    // console.log("dataFlatReviews:", dataFlatReviews)
});




onMounted(async() => {
  if (props.centerNew === undefined && restaurantName.value === null && city.value === null) {
    center.value = { lat: 52.15047620106994, lng: 18.84131026512975 };
    zoom.value = 7;   
  } 
  else if (restaurantName.value){
    zoom.value = 14;
    firstVisit.value = false;
  } 
  else {
    center.value = props.centerNew;
    zoom.value = props.zoomNew;
    search.value = props.nameNew;
    firstVisit.value = false;
  }
});




const updateHandler = () => {
  open.value = true;
};

onMounted(() => {
  document.addEventListener('swUpdated', updateHandler);
});

onUnmounted(() => {
  document.removeEventListener('swUpdated', updateHandler);
});




const refreshPage = () => {
        window.location.reload();
}   

const city = computed(() => {
  return route.params.city ? route.params.city : null; 
});

const restaurantName = computed(() => {
  if (route.params.restaurantName) {
    return decodeURIComponent(route.params.restaurantName.replace('.html', ''));
  }
  return null; 
});



onBeforeRouteLeave((to, from, next) => {
  const fromSegments = from.path.split('/').filter(Boolean); // filter(Boolean) usuwa puste stringi
  const toSegments = to.path.split('/').filter(Boolean);


    // restauracje miasto 1 do miasto 1
    if (fromSegments.length === 2 && toSegments.length === 1 && fromSegments[0] === toSegments[0]) {
      // console.log("🔶 RES miasto 1 do miasto 1")
      zoom.value = 13;
      // console.log("zoom.value:", zoom.value)
    }


    // restauracja miasto 1 do miasto 2
    if (fromSegments.length === 2 && toSegments.length === 1 && fromSegments[0] !== toSegments[0]) {
      // console.log("🔶 RES miasto 1 do miasto 2")
      markers.value = [...allMarkers.value];
      selectedMarker.value = null;

      const cityFound = locationRoutes.find(obj => obj.path === `/${toSegments[0]}`);
      const newZoom = cityFound ? cityFound.zoom : 13;
      zoom.value = newZoom;
    }

    // miasto 1 do miasto 2
    if (fromSegments.length === 1 && toSegments.length === 1 && fromSegments[0] !== toSegments[0]) {
      // console.log("🔶 miasto 1 do miasto 2")

      markers.value = [...allMarkers.value];
      const cityFound = locationRoutes.find(obj => obj.path === `/${toSegments[0]}`);
      const newZoom = cityFound ? cityFound.zoom : 13;
      zoom.value = newZoom;
    }

    // glowna do miasto 
    if (fromSegments.length === 0 && toSegments.length === 1) {
      // console.log("🔶 glowna do miasto ")
   
      const cityFound = locationRoutes.find(obj => obj.path === `/${toSegments[0]}`);
      const newZoom = cityFound ? cityFound.zoom : 13;
      zoom.value = newZoom;
  }

  next();
});







function findLocation(city, restaurantName, allMarkers) {
  // Filtruj obiekty restauracji po nazwie
  const matchingRestaurants = allMarkers.filter(restaurant =>
    prepareUrlName(restaurant.title) === prepareUrlName(restaurantName)
  );

  // console.log("matchingRestaurants.length:", matchingRestaurants.length)
  // console.log("matchingRestaurants:", matchingRestaurants)

  let foundRestaurant = null;

  if (matchingRestaurants.length === 0) {
    router.push({ name: 'NotFound' });
  } 
  else if (matchingRestaurants.length === 1) {
    foundRestaurant = matchingRestaurants[0];
  } 
  else if (matchingRestaurants.length > 1) {
    foundRestaurant = matchingRestaurants.find(restaurant =>
    prepareUrlName(restaurant.adres.city) === prepareUrlName(city)
    
    );
    // TODO trzeba zrobic prawidlowe porownanie dla przypadku gdy 2x Restauracja o tej samej nazwie w tym samym miescie
  }

  if (foundRestaurant) {
    selectedMarker.value = foundRestaurant;
    selectedMarkerId.value = foundRestaurant.id; 
    center.value = foundRestaurant.position;
    zoom.value = 14;
    markers.value = [foundRestaurant];

    // console.log("foundRestaurant.position:", foundRestaurant.position)
    // console.log("center.value:", center.value)
  }


}





// Pierwszy obserwator dla city i restaurantName
watch([city, restaurantName], ([newCity, newRestaurantName], [oldCity, oldRestaurantName]) => {
  if ((newCity !== oldCity || newRestaurantName !== oldRestaurantName) && newCity !== null && newRestaurantName !== null && allMarkersRaw.value.length > 0) {
    findLocation(newCity, newRestaurantName, allMarkersRaw.value);
  }
}, { immediate: true });

// Drugi obserwator specjalnie dla zmian w allMarkersRaw.value.length
watch(() => allMarkersRaw.value.length, (newLength, oldLength) => {
  if (newLength > 0 && city.value !== null && restaurantName.value !== null) {
    findLocation(city.value, restaurantName.value, allMarkersRaw.value);
  }
}, { immediate: true });



// Watcher dla filtru "Wszystkie"
watch(
  () => filtersData.value[0].enabled,
  (newVal) => {
    if (newVal) {
      filtersData.value.forEach((filter, index) => {
        if (index !== 0) filter.enabled = true;
      });
    }
  }
);

// Watcher dla pozostałych filtrów
watch(
  () => filtersData.value.slice(1).map((filter) => filter.enabled),
  (newVals) => {
    // Jeśli jakikolwiek z filtrów jest wyłączony, wyłącz filtr "Wszystkie"
    const allEnabled = newVals.every((enabled) => enabled);
    filtersData.value[0].enabled = allEnabled;
  },
  {
    deep: true, 
  }
);



const isFilteredCompound = computed(() => {
  return filtersData.value.some(filter => filter.enabled === false);
});



watch(selectedMarker, (newValue, oldValue) => {
  if (newValue) {
    center.value = newValue.position;
  }
});






</script>




<style>
.mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgb(255 153 58) 4%,
    rgb(255 179 0 / 30%)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.shadow-md-top {
  box-shadow: 0 -10px 15px -3px rgba(0, 0, 0, 0.1),
    0 -4px 6px -2px rgba(0, 0, 0, 0.05);
}



</style>
