export function convertDateFormat(dateStr) {
    // Tworzenie obiektu Date z podanego stringa
    const dateObj = new Date(dateStr);

    // Pobieranie dnia, miesiąca i roku
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Miesiące są od 0 do 11
    const year = dateObj.getFullYear();

    // Łączenie w pożądany format
    return `${day}-${month}-${year}`;
}